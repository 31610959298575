import React, { useEffect, useState } from "react";
import {
  Column,
  LeftSide,
  NumberHolder,
  Row,
  Subtitle,
  Title,
  Number,
  NumberArrows,
  Price,
  FeatureRow,
  FeatureColumn,
  Line,
  CheckBox,
  AddOnText,
  AddOnDescription,
  AddOnPrice,
  AddOnRow,
  AddOnColumn,
  Taxes,
  Vat,
  TotalVat,
  TotalPrice,
  PerksRow,
  Perks,
  RightSide,
} from "./CheckoutStyle";
import ArrowUp from "./images/Icons/ArrowUp.svg";
import ArrowDown from "./images/Icons/ArrowDown.svg";
import Create from "./images/Icons/CreateConvert.svg";
import Edit from "./images/Icons/EditSource.svg";
import Read from "./images/Icons/ReadFillSign.svg";
import Check from "./images/Icons/Check.svg";
import Headphones from "./images/Icons/Headphones.svg";
import Lock from "./images/Icons/Lock.svg";
import Shield from "./images/Icons/Shield.svg";
import useCheckout from "../../Zustand/CheckoutStore";
import { productInfo } from "../../helpers/PriceCalculator";
import useLanguage from "../../Zustand/LanguageStore";
import { useIntl } from "gatsby-plugin-react-intl";

function RightSideCheckout({ step }) {
  const { setAddOn, AddOn, Amount, setAmount, RealPath } = useCheckout();

  const intl = useIntl();

  const { language } = useLanguage();

  const [price, setPrice] = useState(productInfo(RealPath, Amount, AddOn));
  const [myTag, setMyTag] = useState("");
  const [product, setProduct] = useState("PDF Creator");

  useEffect(() => {
    if (RealPath.includes("pdf-pro")) {
      setProduct("PDF PRO");
    }
    if (RealPath.includes("pdf-pro-upgrade")) {
      setProduct(
        `PDF PRO - ${intl.formatMessage({
          id: "Upgrade",
        })}`
      );
    }
    if (RealPath.includes("pdf-creator-maintenance")) {
      setProduct("Maintenance");
    }
    setMyTag(window?.myTag);
    console.log("My Tag: ", myTag);
  }, [language]);

  useEffect(() => {
    console.log("Language: ", productInfo(RealPath, Amount, AddOn));
    setPrice(productInfo(RealPath, Amount, AddOn));
  }, [AddOn, Amount, language]);

  return (
    <RightSide>
      <Row>
        <Column>
          {product !== "Maintenance" && (
            <>
              <Title>{product}</Title>
              <Subtitle>
                {intl.formatMessage({
                  id: "OneTimePurchase",
                })}
              </Subtitle>
            </>
          )}
          {product === "Maintenance" && (
            <>
              <Title>
                {intl.formatMessage({
                  id: "MaintenacePlan",
                })}
              </Title>
              <Subtitle maintenance={true}>
                <div>
                  {intl.formatMessage({
                    id: "MaintenancePlanPerkOne",
                  })}
                </div>
                <div>
                  {intl.formatMessage({
                    id: "MaintenancePlanPerkTwo",
                  })}
                </div>
              </Subtitle>
            </>
          )}
        </Column>
        <NumberHolder gray={step === 2}>
          <Number
            value={Amount}
            disabled={step === 2}
            onChange={(e) => {
              if (step !== 2)
                setAmount(e.target.value > 0 ? parseInt(e.target.value) : 1);
            }}
          />
          <NumberArrows>
            <ArrowUp
              onClick={() => {
                if (step !== 2) setAmount(Amount + 1);
              }}
            />
            <ArrowDown
              onClick={() => {
                if (step !== 2) setAmount(Amount > 1 ? Amount - 1 : Amount);
              }}
            />
          </NumberArrows>
        </NumberHolder>
        <Price>{price.productPrice}</Price>
      </Row>
      <FeatureColumn>
        <FeatureRow>
          <Create />
          <div>
            {" "}
            {intl.formatMessage({
              id: "Create&Convert",
            })}
          </div>
        </FeatureRow>
        <FeatureRow>
          <Edit />
          <div>
            {" "}
            {intl.formatMessage({
              id: "Edit&Secure",
            })}
          </div>
        </FeatureRow>
        <FeatureRow>
          <Read />
          <div>
            {" "}
            {intl.formatMessage({
              id: "Read,Fill,&Sign",
            })}
          </div>
        </FeatureRow>
      </FeatureColumn>
      <Line />
      {product !== "Maintenance" && !RealPath.includes("upgrade") && (
        <>
          <AddOnRow>
            <CheckBox
              gray={step === 2}
              onClick={() => {
                if (step !== 2) setAddOn(!AddOn);
              }}
            >
              {AddOn && <Check />}
            </CheckBox>
            <AddOnColumn>
              <AddOnText>
                {intl.formatMessage({
                  id: "DownloadBackup",
                })}
              </AddOnText>
              <AddOnDescription>
                {intl.formatMessage({
                  id: "EDS",
                })}
              </AddOnDescription>
            </AddOnColumn>
            <AddOnPrice>{price.addOnPrice}</AddOnPrice>
          </AddOnRow>
          <Line />
        </>
      )}
      <Row>
        <TotalVat>
          TOTAL {myTag !== "US" && <span>({price?.currency})</span>}
          {(myTag === "US" || myTag === "CA" || myTag === "AU") && (
            <Taxes>
              {intl.formatMessage({
                id: "BeforeTaxes",
              })}
            </Taxes>
          )}
        </TotalVat>
        <TotalPrice>{price?.totalPriceFormatter}</TotalPrice>
      </Row>
      <Line />
      <PerksRow>
        <Perks>
          <Headphones />
          <div>
            {intl.formatMessage({
              id: "DedicatedSupport",
            })}
          </div>
        </Perks>
        <Perks>
          <Lock />
          <div>
            {" "}
            {intl.formatMessage({
              id: "SecureTransactions",
            })}
          </div>
        </Perks>
        <Perks>
          <Shield />
          <div>
            {intl.formatMessage({
              id: "MoneyBack",
            })}
          </div>
        </Perks>
      </PerksRow>
    </RightSide>
  );
}

export default RightSideCheckout;
