import React, { useEffect, useState } from "react";
import {
  LeftColumn,
  Form,
  FormTitle,
  Input,
  Row,
  Line,
  FSMask,
  FormHolder,
  FormWrapper,
  UpperFormHolder,
  ResetButton,
  PaymentButton,
  Modify,
} from "./Step2Embedded.styled";

import { useIntl } from "gatsby-plugin-react-intl";

import useCheckout from "../../Zustand/CheckoutStore";
import PopupBuilder from "./Embedded/PopupBuilder";
import useLanguage from "../../Zustand/LanguageStore";

function LeftSide2({ setRefresh, refresh, setStep }) {
  const [fnameEmpty, setFnameEmpty] = useState(false);
  const [lnameEmpty, setLnameEmpty] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);

  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const [mount, setMount] = useState(false);

  const intl = useIntl();

  const {
    setEmail,
    setFname,
    setLname,

    email,
    lname,
    fname,
  } = useCheckout();
  const { language } = useLanguage();

  useEffect(() => {
    if (mount) {
      setStep(1);
      setRefresh(true);
    }
    setMount(true);
  }, [language]);

  useEffect(() => {
    document.getElementById("pay-button")?.click();
  }, []);

  useEffect(() => {
    const container = document.getElementById(
      "fsc-embedded-checkout-container"
    );

    const containerObserver = new MutationObserver(function (mutationsList) {
      for (let mutation of mutationsList) {
        console.log("Change detected in container!", loading);
        if (loading) {
          console.log("Change detected in container! Loading done!");
          setTimeout(() => {
            setLoaded(true);
            setLoading(false);
            setUpdated(false);
            setTimeout(() => {
              window.focus();
            }, 100);
          }, 1000);
        }
      }
    });

    if (container)
      containerObserver.observe(container, { childList: true, subtree: true });
  }, [loading, loaded]);

  return (
    <LeftColumn>
      <FormWrapper loaded={loaded} updated={updated}>
        <Form>
          <UpperFormHolder>
            <FormTitle>
              {" "}
              {intl.formatMessage({
                id: "OrderDetails",
              })}
            </FormTitle>

            <Row>
              <Input
                placeholder={
                  fnameEmpty
                    ? intl.formatMessage({
                        id: "FNRequired",
                      })
                    : `${intl.formatMessage({
                        id: "FirstName",
                      })}*`
                }
                type="text"
                onChange={(e) => {
                  setFname(e.target.value);
                }}
                value={fname}
                empty={fnameEmpty}
                id="FirstName"
                disabled={true}
              />
              <Input
                placeholder={
                  lnameEmpty
                    ? intl.formatMessage({
                        id: "LNRequired",
                      })
                    : `${intl.formatMessage({
                        id: "LastName",
                      })}*`
                }
                type="text"
                onChange={(e) => {
                  setLname(e.target.value);
                }}
                value={lname}
                empty={lnameEmpty}
                id="LastName"
                disabled={true}
              />
            </Row>

            <Input
              placeholder={
                emailEmpty
                  ? intl.formatMessage({
                      id: "EmailRequired",
                    })
                  : validEmail
                  ? `${intl.formatMessage({
                      id: "Email",
                    })}*`
                  : intl.formatMessage({
                      id: "EmailInvalid",
                    })
              }
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
                setValidEmail(true);
              }}
              value={email}
              empty={emailEmpty || !validEmail}
              id="Email"
              disabled={true}
            />
            <Modify
              onClick={() => {
                setStep(1);
                setRefresh(true);
              }}
            >
              {intl.formatMessage({
                id: "ModifyPlan",
              })}
            </Modify>
            <Line />
          </UpperFormHolder>

          <div>
            <div style={{ position: "relative" }}>
              {loaded && updated && (
                <FSMask loaded={loaded}>
                  <PopupBuilder
                    setFnameEmpty={setFnameEmpty}
                    setLnameEmpty={setLnameEmpty}
                    setEmailmEpty={setEmailEmpty}
                    setLoading={setLoading}
                    loading={loading}
                    ButtonStyled={ResetButton}
                    title={loading ? "Recalculating..." : "Recalculate"}
                  />
                </FSMask>
              )}
              {!refresh && (
                <FormHolder
                  id="fsc-embedded-checkout-container"
                  loaded={loaded}
                  updated={updated}
                ></FormHolder>
              )}
            </div>
          </div>
          {!loaded && (
            <PopupBuilder
              setFnameEmpty={setFnameEmpty}
              setLnameEmpty={setLnameEmpty}
              setEmailmEpty={setEmailEmpty}
              setLoading={setLoading}
              loading={loading}
              ButtonStyled={PaymentButton}
              title={
                loading
                  ? intl.formatMessage({
                      id: "SecuringCheckout",
                    })
                  : intl.formatMessage({
                      id: "ToPayment",
                    })
              }
            />
          )}
        </Form>
        {/* <FormSecurities>
          <FormSecurity>
            <Headphones />
            <FormSecurityText>Dedicated Customer Support</FormSecurityText>
          </FormSecurity>
          <FormSecurity>
            <Lock />
            <FormSecurityText>100% Secure Transaction</FormSecurityText>
          </FormSecurity>
          <FormSecurity>
            <Shield />
            <FormSecurityText>30-Day Money-Back Guarantee</FormSecurityText>
          </FormSecurity>
        </FormSecurities> */}
      </FormWrapper>
    </LeftColumn>
  );
}

export default LeftSide2;
