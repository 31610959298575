import React, { useEffect } from "react";
import styled from "styled-components";
import PayButton from "./payButton";
import { useState } from "react";
import axios from "axios";

import { useIntl } from "gatsby-plugin-react-intl";

import useCheckout from "../../../Zustand/CheckoutStore";

const ErrorMessage = styled.p`
  margin: 0;
  color: #eb2222;
  font-weight: 600;
`;

const Holder = styled.div`
  width: 100%;
  height: max-content;
  margin-top: auto;
  margin-bottom: 10px;
`;

function PopupBuilder({
  setFnameEmpty,
  setLnameEmpty,
  setEmailmEpty,
  setPhoneEmpty,
  setCardInvalid,
  setExpirationInvalid,
  setSecurityCodeInvalid,
  setZipCodeInvalid,
  ButtonStyled,
  title,
  setLoading,
  loading,
}) {
  const {
    RealPath,
    Amount,
    fname,
    lname,
    email,
    phoneNumber,
    cardNumber,
    expirationDate,
    securityCode,
    zipCode,
    AddOn,
    paymentGateway,
  } = useCheckout();
  const [emailError, setEmailError] = useState("");
  const [alreadyInUse, setAlreadyInUse] = useState(false);
  const basicEmailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const intl = useIntl();

  useEffect(() => {
    if (zipCode != "") setZipCodeInvalid(false);

    if (paymentGateway === "Card") {
      if (cardNumber != "") setCardInvalid(false);
      if (expirationDate != "") setExpirationInvalid(false);
      if (securityCode != "") setSecurityCodeInvalid(false);
    }

    if (fname != "") setFnameEmpty(false);
    if (lname != "") setLnameEmpty(false);
    if (email != "") setEmailmEpty(false);

    setAlreadyInUse(false);
  }, [fname, lname, email, phoneNumber]);

  function onSubmit() {
    /// Enhanced conversion tracking
    localStorage.setItem("email", email);
    localStorage.setItem("firstName", fname);
    localStorage.setItem("lastName", lname);
    localStorage.setItem("country", window.myTag);
    /// Enhanced conversion tracking

    let itemsSettings = [
      {
        product: RealPath,
        quantity: Amount,
      },
    ];

    if (AddOn) {
      itemsSettings.push({
        product: "eds",
        quantity: 1,
      });
    }
    // Example how can you actually even change prices through itemsSettings,
    // after this get documented, delete this

    // if (ProductPathDiscount != null) {
    //   let product = productInfo(
    //     RealPath,
    //     Amount,
    //     ProductDiscount,
    //     OcrDiscount,
    //     ProductPathDiscount
    //   );
    //   let price = {};
    //   let discount = {};
    //   price[product.currencyName] = product.finalProductPrice;
    //   discount[product.currencyName] = product.finalProductDiscount;
    //   itemsSettings.pricing = {
    //     price,
    //     quantityDiscounts: {
    //       1: discount,
    //     },
    //   };
    // }

    console.log("itemsSettings: ", itemsSettings);

    // Encrypting the payload, this is a must-have

    const payload = {
      email: email,
      firstName: fname,
      lastName: lname,
      phone: "1",
    };

    axios
      .post(`https://backend.pdfpro.com/api/encrypted`, {
        contact: payload,
        items: itemsSettings,
      })
      .then((res) => {
        const resEncrypted = res.data.payload;
        // Setting PopUp language
        console.log("Lang: ", window.myTag);

        window.fastspring.builder.push({
          reset: true,
          country: window.myTag,
          language: intl.formatMessage({
            id: "Lang",
          }),
          email: email,
          firstName: fname,
          lastName: lname,
        });

        console.log("resEncrypted: ", resEncrypted);

        // Encrypting the payload
        window.fastspring.builder.secure(
          resEncrypted.securePayload,
          resEncrypted.secureKey
        );

        window.fastspring.builder.checkout();
      })
      .catch((err) => console.log(err));
  }

  const EmailCheck = async (e) => {
    if (basicEmailRegex.test(email) && fname !== "" && lname !== "") {
      console.log("START SUBMIT");
      setLoading(true);
      console.log("AFTER LOADING");

      onSubmit();
      console.log("END SUBMIT");
    } else {
      if (fname === "") {
        setFnameEmpty(true);
      } else {
        setFnameEmpty(false);
      }
      if (lname === "") {
        setLnameEmpty(true);
      } else {
        setFnameEmpty(false);
      }
      if (email === "") {
        setEmailmEpty(true);
      } else {
        setFnameEmpty(false);
      }
      console.log("Invalid Email");
    }
  };

  return (
    <Holder>
      <PayButton
        className="payment"
        name={title}
        // disabled={isDisabled}
        testit={EmailCheck}
        loading={loading}
        onClick={() => {
          // This will be updated to GA4
          setTimeout(() => window.scrollTo(0, 0), 100);
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            event: "buttonClick",
            category: "Reached checkout 3.step",
            action: "Clicked Pay Button",
            label: "Clicked on Pay Button - opened FastSpring Popup",
          });
        }}
        StyledComponent={ButtonStyled}
      ></PayButton>

      {alreadyInUse && <ErrorMessage>Email in use</ErrorMessage>}
    </Holder>
  );
}

export default PopupBuilder;
