import React, { useEffect, useState } from "react";
import {
  LeftColumn,
  Form,
  FormTitle,
  Input,
  Row,
  Line,
  PaymentButton,
  FormWrapper,
  FormSecurities,
  FormSecurity,
  FormSecurityText,
  UpperFormHolder,
  ErrorMessage,
} from "./Step2Embedded.styled";
import Headphones from "./images/Icons/HeadphonesBig.svg";
import Shield from "./images/Icons/ShieldBig.svg";
import Lock from "./images/Icons/LockBig.svg";
import useCheckout from "../../Zustand/CheckoutStore";

import { useIntl } from "gatsby-plugin-react-intl";

function LeftSide({ setRefresh, refresh, setStep, langChange, setLangChange }) {
  const [fnameEmpty, setFnameEmpty] = useState(false);
  const [lnameEmpty, setLnameEmpty] = useState(false);
  const [emailEmpty, setEmailEmpty] = useState(false);
  const [alreadyInUse, setAlreadyInUse] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [validEmail, setValidEmail] = useState(true);
  const intl = useIntl();

  const { setEmail, setFname, setLname, email, lname, fname } = useCheckout();

  useEffect(() => {
    console.log("langChange: ", langChange);
    if (langChange) {
      setTimeout(() => document.getElementById("pay-button").click(), 100);
      setLangChange(false);
    }
  }, [langChange]);

  return (
    <LeftColumn>
      <FormWrapper>
        <Form>
          <UpperFormHolder>
            <FormTitle>
              {intl.formatMessage({
                id: "OrderDetails",
              })}
            </FormTitle>
            <Row>
              <Input
                placeholder={
                  fnameEmpty
                    ? intl.formatMessage({
                        id: "FNRequired",
                      })
                    : `${intl.formatMessage({
                        id: "FirstName",
                      })}*`
                }
                type="text"
                onChange={(e) => {
                  setFname(e.target.value);
                }}
                value={fname}
                empty={fnameEmpty}
                id="FirstName"
              />
              <Input
                placeholder={
                  lnameEmpty
                    ? intl.formatMessage({
                        id: "LNRequired",
                      })
                    : `${intl.formatMessage({
                        id: "LastName",
                      })}*`
                }
                type="text"
                onChange={(e) => {
                  setLname(e.target.value);
                }}
                value={lname}
                empty={lnameEmpty}
                id="LastName"
              />
            </Row>

            <Input
              placeholder={
                emailEmpty
                  ? intl.formatMessage({
                      id: "EmailRequired",
                    })
                  : validEmail
                  ? `${intl.formatMessage({
                      id: "Email",
                    })}*`
                  : intl.formatMessage({
                      id: "EmailInvalid",
                    })
              }
              type="email"
              onChange={(e) => {
                setEmail(e.target.value);
                setValidEmail(true);
              }}
              value={email}
              empty={emailEmpty || !validEmail}
              id="Email"
            />

            <Line />
          </UpperFormHolder>

          {!loaded && (
            <PaymentButton
              id="pay-button"
              onClick={async () => {
                if (fname === "" || !fname) setFnameEmpty(true);
                if (lname === "" || !lname) setLnameEmpty(true);
                if (email === "" || !email) setEmailEmpty(true);
                const regex =
                  /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                if (!regex.test(email)) {
                  setValidEmail(false);
                  setEmail("");
                }

                setTimeout(() => window.scrollTo(0, 0), 100);
                console.log(fname, lname, email, regex.test(email));
                if (
                  fname !== "" &&
                  lname !== "" &&
                  email !== "" &&
                  fname &&
                  lname &&
                  email &&
                  regex.test(email)
                ) {
                  setStep(2);
                }
              }}
            >
              {loading
                ? intl.formatMessage({
                    id: "SecuringCheckout",
                  })
                : intl.formatMessage({
                    id: "ToPayment",
                  })}
            </PaymentButton>
          )}
        </Form>
        {/* <FormSecurities>
          <FormSecurity>
            <Headphones />
            <FormSecurityText>Dedicated Customer Support</FormSecurityText>
          </FormSecurity>
          <FormSecurity>
            <Lock />
            <FormSecurityText>100% Secure Transaction</FormSecurityText>
          </FormSecurity>
          <FormSecurity>
            <Shield />
            <FormSecurityText>30-Day Money-Back Guarantee</FormSecurityText>
          </FormSecurity>
        </FormSecurities> */}
      </FormWrapper>
    </LeftColumn>
  );
}

export default LeftSide;
